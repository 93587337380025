import React, { useState, useRef } from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { NavLink } from 'react-router-dom';
import General_FAQ_Seo from '../../SEO/General_FAQ_Seo';

function GeneralFAQ() {
  const [activeIndex, setActiveIndex] = useState(null);
  const contentRefs = useRef([]);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };


  return (
    <>
      <General_FAQ_Seo />
      <Header />
      {/* Main Body - Start================================================== */}
      <main className="page_content">
        {/* Page Header - Start================================================== */}
        <section className="page_header text-center bg-dark section_decoration overflow-hidden" style={{ backgroundImage: 'url("assets/images/backgrounds/page_header_bg_1.webp")' }}>
          <div className="container">
            <h1 className="page_title text-white">General FAQs</h1>
            <ul className="breadcrumb_nav unordered_list justify-content-center">
              <li><NavLink to="/">Home</NavLink></li>
              <li className="active"><NavLink to="/general-faq">General FAQs</NavLink></li>
            </ul>
          </div>
        </section>
        {/* Page Header - End================================================== */}

        {/* FAQ Section - Start================================================== */}
        <section className="faq_section section_space">
          <div className="container">
            <div className="heading_block style_2 text-center">
              <div className="badge text-uppercase text-dark bg-dark-subtle">Our FAQ'S</div>
              <h2 className="heading_text mb-0">
                Frequently Ask Questions
              </h2>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="accordion style_2" id="faq_accordion">
                  <div className="accordion-item">
                    <div className="accordion-header">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_one" aria-expanded="true" aria-controls="collapse_one">
                        Why do you need my documents while registration?
                      </button>
                    </div>
                    <div id="collapse_one" className="accordion-collapse collapse show" data-bs-parent="#faq_accordion">
                      <div className="accordion-body">
                        <p className="m-0">
                          We are regulated by Financial Conduct Authority in the UK. It is our legal binding to verify your identity as a UK resident.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_two" aria-expanded="false" aria-controls="collapse_two">
                        How can I cancel my transfer?
                      </button>
                    </div>
                    <div id="collapse_two" className="accordion-collapse collapse" data-bs-parent="#faq_accordion">
                      <div className="accordion-body">
                        <p className="m-0">
                          Please call us or email us at earliest possible if you want to cancel your transfer. Transfer paid out to the recipient cannot be cancelled. If you have paid for your transfer and the transfer is not processed yet, then please contact us by phone and we will make necessary arrangements for refunds. We may charge you a small fee as handling/service charge and refund you the balance amount.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_three" aria-expanded="false" aria-controls="collapse_three">
                        Will I get notifications about Transfer status?
                      </button>
                    </div>
                    <div id="collapse_three" className="accordion-collapse collapse" data-bs-parent="#faq_accordion">
                      <div className="accordion-body">
                        <p className="m-0">
                          Yes. We send email notifications to keep you informed. You can even view the transfer status by signing in into your web account.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_four" aria-expanded="false" aria-controls="collapse_four">
                        How many recipients I can add?
                      </button>
                    </div>
                    <div id="collapse_four" className="accordion-collapse collapse" data-bs-parent="#faq_accordion">
                      <div className="accordion-body">
                        <p className="m-0">
                          There is no restriction on the number of recipients you may have on your account.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_five" aria-expanded="false" aria-controls="collapse_five">
                        You asked me to upload one more document; how can I do that?
                      </button>
                    </div>
                    <div id="collapse_five" className="accordion-collapse collapse" data-bs-parent="#faq_accordion">
                      <div className="accordion-body">
                        <p className="m-0">
                          Please login in to your account and under My Account you will find document section where you can upload the document.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_six" aria-expanded="false" aria-controls="collapse_six">
                        How can I pay for my transfer?
                      </button>
                    </div>
                    <div id="collapse_six" className="accordion-collapse collapse" data-bs-parent="#faq_accordion">
                      <div className="accordion-body">
                        <p className="m-0">
                          Bank Transfer to our business account and payments made by using credit or debit cards. Bank Transfer is the recommended and preferred method of payment. There may be restrictions on credit and debit card payment as well as there may be a fee charged on top.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_seven" aria-expanded="false" aria-controls="collapse_seven">
                        Why I received email stating transfer complete, but the money is not with recipient?
                      </button>
                    </div>
                    <div id="collapse_seven" className="accordion-collapse collapse" data-bs-parent="#faq_accordion">
                      <div className="accordion-body">
                        <p className="m-0">
                          Our pay-out partner bank/agent may have completed the transfer process but the recipient's bank would not have displayed it in their account. Please allow couple of hours to be displayed, if the problem persists then please contact our support team. We will make sure money is with your recipient as promised.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_eight" aria-expanded="false" aria-controls="collapse_eight">
                        Good to know
                      </button>
                    </div>
                    <div id="collapse_eight" className="accordion-collapse collapse" data-bs-parent="#faq_accordion">
                      <div className="accordion-body">
                        <ul className="list m-0">
                          <li>Age: 18 years and above</li>
                          <li>We will verify your identity, this will not affect your credit ratings</li>
                          <li>You must be resident of the UK</li>
                          <li>Activation of account will be based on the documents you provide</li>
                          <li>Monthly sending limits may apply</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <div className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_nine" aria-expanded="false" aria-controls="collapse_nine">
                        Documents Required
                      </button>
                    </div>
                    <div id="collapse_nine" className="accordion-collapse collapse" data-bs-parent="#faq_accordion">
                      <div className="accordion-body">
                        <ul class="list mb-0">
                          <li>Photo Identity Proof (Passport, EU Nationality card or UK Driving License).</li>
                          <li>Address Proof (Utility bill, Bank Statement, Council Tax bill not less than 3 months old).</li>
                          <li>Source of funds document.</li>
                          <li>Company Houses Registration (mandatory for Corporate Accounts).</li>
                          <li>Company Address Proof (mandatory for Corporate Accounts).</li>
                          <li>Company Bank Statement (mandatory for Corporate Accounts).</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FAQ Section - End================================================== */}
      </main>
      {/* Main Body - End================================================== */}
      <Footer />
    </>
  );
}

export default GeneralFAQ;
