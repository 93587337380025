import React from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { NavLink } from 'react-router-dom';
import Home_Seo from '../../SEO/Home_Seo';
import Calculator from '../../Components/Calculator';
import CorridorsSlider from '../../Components/CorridorsSlider';

function Home() {
  return (
    <>
      <Home_Seo />
      <Header />
      {/* Main Body - Start================================================== */}
      <main className="page_content">
        {/* Hero Section - Start================================================== */}
        <section className="hero_section hero_online_banking overflow-hidden section_decoration bg-dark" style={{ backgroundImage: 'url("assets/images/backgrounds/hero_bg_noise.webp")' }}>
          <div className="overlay" style={{ backgroundImage: 'url("assets/images/hero/hero_pattern.svg")' }} />
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <div className="badge text-uppercase text-primary wow fadeInUp" data-wow-delay=".1s">Fast, Secure, and Reliable</div>
                <h1 className="hero_title text-white wow fadeInUp" data-wow-delay=".2s">
                  Money Transfer Service
                </h1>
                <p className="pe-lg-5 text-white wow fadeInUp" data-wow-delay=".3s">
                Experience quick, safe, and trustworthy money transfers with us. We ensure your funds reach their destination without any hassle!
                </p>
                <ul className="btns_group pb-0 unordered_list justify-content-lg-start wow fadeInUp" data-wow-delay=".4s">
                  <li className='d-flex'>
                    <NavLink className="app-btn" target='_blank' to="https://play.google.com/store/apps/details?id=com.remit.Riyoremit">
                      <img className='' src="assets/images/google_play.webp" alt='Google App' />
                    </NavLink>
                    <NavLink className="app-btn" target='_blank' to="https://apps.apple.com/us/app/riyo-remit/id6738699004">
                      <img className='' src="assets/images/app_store.webp" alt='App Store App' />
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className='col-lg-5'>
              <Calculator/>
              </div>
            </div>
          </div>
          <div className="hero_shape_image">
            <img src="assets/images/hero/hero_shape_1.svg" alt="Hero Shape" />
          </div>
          <div className="container">
            <CorridorsSlider />
          </div>
          <div className="decoration_item icon_star_1">
            <img src="assets/images/icons/icon_star_4.svg" alt="Icon Star" />
          </div>
          <div className="decoration_item icon_star_2">
            <img src="assets/images/icons/icon_star_3.svg" alt="Icon Star" />
          </div>
          <div className="decoration_item shape_shadow_1">
            <img src="assets/images/shapes/shape_circle_2.svg" alt="Shape Shadow 1" />
          </div>
          <div className="decoration_item shape_shadow_2">
            <img src="assets/images/shapes/shape_circle_2.svg" alt="Shape Shadow 2" />
          </div>
          <div className="decoration_item shape_shadow_3">
            <img src="assets/images/shapes/shape_circle_2.svg" alt="Shape Shadow 3" />
          </div>
          <div className="decoration_item shape_shadow_4">
            <img src="assets/images/shapes/shape_circle_2.svg" alt="Shape Shadow 4" />
          </div>
        </section>
        {/* Hero Section - End================================================== */}

        {/* Feature Section - Start================================================== */}
        <section className="feature_section section_space">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="heading_block style_2 text-center">
                  <div className="badge text-uppercase text-dark bg-dark-subtle">Why Choose Us?</div>
                  <h2 className="heading_text mb-0">
                    Five Rapid Highlights Of Our Riyo Remit
                  </h2>
                </div>
              </div>
            </div>
            <div className="row online_banking_features">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="imagebox_block pb-0 bg-success">
                  <div className="imagebox_content">
                    <div className="imagebox_icon">
                      <img src="assets/images/icons/quicktransfer.svg" alt="Quick Transfer" />
                    </div>
                    <h3 className="imagebox_title">
                      Quick Transfer
                    </h3>
                    <p className="mb-0">
                    Send money quickly and securely with Quick Transfer – reliable, efficient, and at your fingertips whenever you need it.
                    </p>
                  </div>
                  <div className="image_block">
                    <img src="assets/images/features/ob_feature_image_1.webp" alt="Feature Image" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="imagebox_block pb-0 bg-success">
                  <div className="imagebox_content">
                    <div className="imagebox_icon">
                      <img src="assets/images/icons/lowcost.svg" alt="Low Cost" />
                    </div>
                    <h3 className="imagebox_title">
                      Low Cost
                    </h3>
                    <p className="mb-0">
                    Enjoy affordable transfers with our Low-Cost service, offering competitive rates without compromising on speed or security.
                    </p>
                  </div>
                  <div className="image_block">
                    <img src="assets/images/features/ob_feature_image_2.webp" alt="Feature Image" />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="imagebox_block pb-0 bg-info">
                  <div className="imagebox_content">
                    <div className="imagebox_icon">
                      <img src="assets/images/icons/sendmoney.svg" alt="Send Money Anytime" />
                    </div>
                    <h3 className="imagebox_title">
                      Send Money Anytime
                    </h3>
                    <p className="mb-0">
                    Send Money Anytime lets you transfer funds whenever it suits you, with 24/7 availability for ultimate convenience.
                    </p>
                  </div>
                  <div className="image_block pb-4">
                    <img src="assets/images/features/ob_feature_image_3.webp" alt="Feature Image" />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="imagebox_block pb-0 bg-info">
                  <div className="imagebox_content">
                    <div className="imagebox_icon">
                      <img src="assets/images/icons/safeandsecure.svg" alt="Safe And Secure" />
                    </div>
                    <h3 className="imagebox_title">
                      Safe And Secure
                    </h3>
                    <p className="mb-0">
                    Your transactions are our top priority – enjoy safe and secure transfers with advanced protection at every step.
                    </p>
                  </div>
                  <div className="image_block">
                    <img src="assets/images/features/ob_feature_image_4.webp" alt="Feature Image" />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="imagebox_block pb-0 bg-info">
                  <div className="imagebox_content">
                    <div className="imagebox_icon">
                      <img src="assets/images/icons/best-rate.svg" alt="Best Rate" />
                    </div>
                    <h3 className="imagebox_title">
                      Best Rate
                    </h3>
                    <p className="mb-5"> 
                    Get the best rates for every transfer – competitive pricing to maximize your savings.
                    </p>
                  </div>
                  <div className="image_block pt-3">
                    <img src="assets/images/features/ob_feature_image_5.webp" alt="Feature Image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Feature Section - End================================================== */}

        {/* About Section - Start================================================== */}
        <section className="about_section section_space pb-0 bg-light">
          <div className="container">
            <div className="row align-items-center justify-content-lg-between">
              <div className="col-lg-5">
                <div className="heading_block style_2">
                  <div className="badge text-uppercase text-dark bg-dark-subtle">Who we are</div>
                  <h2 className="heading_text">
                    Riyo Remit
                  </h2>
                  <p className="heading_description mb-0">
                  At Riyo Remit, we are dedicated to revolutionizing the money transfer experience by offering fast, secure, and reliable transactions. Whether sending funds to loved ones or supporting business needs, our platform guarantees ease and efficiency. With competitive rates, robust security, and 24/7 service, Riyo Remit is here to make international transfers straightforward and accessible for everyone.
                  </p>
                </div>

              </div>
              <div className="col-lg-6">
              <img src="assets/images/about/about.png" alt="About Image 1" />
              </div>
            </div>
          </div>
        </section>
        {/* About Section - End================================================== */}

        {/* Funfact Section - Start================================================== */}
        <section className="funfact_section section_space bg-light">
          <div className="container">
            <div className="heading_block style_2">
              <div className="row align-items-lg-end justify-content-lg-between">
                <div className="col-lg-12">
                  <div className="badge text-uppercase text-dark bg-dark-subtle">How We Work</div>
                  <h2 className="heading_text mb-0">
                    You Can Send Money With The Following Four Easy Steps
                  </h2>
                </div>
              </div>
            </div>
            <div className="row section_decoration">
              <div className="col-lg-4">
                <div className="funfact_block style_2" style={{ backgroundImage: 'url("assets/images/shapes/funfact_bg_4.svg")' }}>
                  <div className="funfact_value">
                    <span>1. Create An Account</span>
                  </div>
                  <h4 className="funfact_title mb-0">
                    Download our app and get signed up in just minutes. It’s quick and completely free!
                  </h4>
                </div>
                <div className="funfact_block style_2" style={{ backgroundImage: 'url("assets/images/shapes/funfact_bg_4.svg")' }}>
                  <div className="funfact_value">
                    <span>2. Set up a transfer</span>
                  </div>
                  <h4 className="funfact_title mb-0">
                    Add your recipient and specify the amount you wish to send or receive in the foreign currency.
                  </h4>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="money_transactions_card bg-dark text-center" style={{ backgroundImage: 'url("assets/images/shapes/shape_line.svg")' }}>
                  <div className="image_block">
                     <img src="assets/images/site_logo/app.png" alt="Site Logo White" />
                  </div>
                  <NavLink className="app-btn" to="https://play.google.com/store/apps/details?id=com.remit.Riyoremit" target='_blank'>
                    <img className='' src="assets/images/google_play.webp" alt='Google App' />
                  </NavLink>
                  <NavLink className="app-btn" to="https://apps.apple.com/us/app/riyo-remit/id6738699004" target='_blank'>
                    <img className='' src="assets/images/app_store.webp" alt='App Store App' />
                  </NavLink>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="funfact_block style_2" style={{ backgroundImage: 'url("assets/images/shapes/funfact_bg_4.svg")' }}>
                  <div className="funfact_value">
                    <span>3. Pay securely</span>
                  </div>
                  <h4 className="funfact_title mb-0">
                    Complete your payment via Bank Transfer-it's the safest and fastest method to ensure your funds are received.
                  </h4>
                </div>
                <div className="funfact_block style_2" style={{ backgroundImage: 'url("assets/images/shapes/funfact_bg_4.svg")' }}>
                  <div className="funfact_value">
                    <span>4. That's it!</span>
                  </div>
                  <h4 className="funfact_title mb-0">
                    Track your transfer from the app. We payout to beneficiary as promised in delivery method.
                  </h4>
                </div>
              </div>
              <div className="decoration_item shape_circle_image">
                <img src="assets/images/shapes/shape_circle_3.svg" alt="Circle Image" />
              </div>
            </div>
          </div>
        </section>
        {/* Funfact Section - End================================================== */}

        {/* App Store Section - Start================================================== */}
        <section className="appstore_section section_decoration" style={{ backgroundImage: 'url("assets/images/backgrounds/app_store_bg_1.webp")' }}>
          <div className="container">
            <div className="row justify-content-lg-between">
              <div className="col-lg-6 order-lg-last">
                <div className="heading_block style_2">
                  <div className="badge text-uppercase text-dark">Download Our App</div>
                  <h2 className="heading_text">
                    Download And Enjoy The Experience
                  </h2>
                  <p className="heading_description mb-0 text-dark">
                    Send money worldwide in seconds with Riyo Remit app. Enjoy fast transfers, competitive rates, and secure transactions. Track your transfers easily, and all from a user-friendly app.
                  </p>
                </div>
                <div className='row'>
                  <div className='col-lg-6 col-6'>
                    <NavLink className="" to="https://play.google.com/store/apps/details?id=com.remit.Riyoremit" target='_blank'>
                      <img className src="assets/images/google_play.webp" alt="Google App" />
                    </NavLink>
                  </div>
                  <div className='col-lg-6 col-6'>
                    <NavLink className="" to="https://apps.apple.com/us/app/riyo-remit/id6738699004" target='_blank'>
                      <img className src="assets/images/app_store.webp" alt="Google App" />
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="app_image">
                  <img src="assets/images/about/mobile_app_image.webp" alt="App Image" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* App Store Section - End================================================== */}


      </main>
      {/* Main Body - End================================================== */}
      {/* Footer Section Here */}
      <Footer />
    </>
  );
}

export default Home;
