import React, { useEffect, useState } from 'react';
import { FaFacebookSquare, FaLinkedin } from 'react-icons/fa';
import { FaSquareXTwitter } from 'react-icons/fa6';
import { NavLink } from 'react-router-dom';

function Footer() {
    const [showCookiesConsent, setShowCookiesConsent] = useState(true); // For Cookies

    const acceptCookies = () => {
        localStorage.setItem("cookiesAccepted", "true");
        setShowCookiesConsent(false);
    };

    // Check if cookies are already accepted on mount
    useEffect(() => {
        const cookiesAccepted = localStorage.getItem("cookiesAccepted");
        if (cookiesAccepted === "true") {
            setShowCookiesConsent(false);
        }
    }, []);

    // Scroll to top function
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div>
            {/* Site Footer - Start================================================== */}
            <footer className="site_footer footer_layout_2 bg-secondary section_decoration section_space" style={{ backgroundImage: 'url("assets/images/backgrounds/hero_bg_noise.webp")' }}>
                <div className="overlay" style={{ backgroundImage: 'url("assets/images/hero/hero_pattern.svg")' }} />
                <div className="container">
                    <div className="content_wrap pb-0">
                        <div className="contact_info row">
                            <div className="col-lg-4">
                                <div className="iconbox_block icon_left">
                                    <div className="iconbox_icon">
                                        <img src="assets/images/icons/icon_email.svg" alt="Icon Email" />
                                    </div>
                                    <div className="iconbox_content">
                                        <h3 className="iconbox_title">Email ID</h3>
                                        <p className="mb-0">
                                            <NavLink to="mailto:info@riyoremit.com">info@riyoremit.com</NavLink>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="iconbox_block icon_left">
                                    <div className="iconbox_icon">
                                        <img src="assets/images/icons/icon_calling_5.svg" alt="Icon Calling" />
                                    </div>
                                    <div className="iconbox_content">
                                        <h3 className="iconbox_title"> Call Us</h3>
                                        <p className="mb-0">
                                            <NavLink to="tel:441217535521">+(44) 121 753 5521</NavLink>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="iconbox_block icon_left">
                                    <div className="iconbox_icon">
                                        <img src="assets/images/icons/icon_map_mark.svg" alt="Icon Map Mark" />
                                    </div>
                                    <div className="iconbox_content">
                                        <h3 className="iconbox_title">Address</h3>
                                        <p className="mb-0">
                                            12-22 Newhall Street, Birmingham, England, B3 3AS
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row justify-content-lg-between">
                            <div className="col-lg-4">
                                <div className="footer_widget pe-md-3">
                                    <h2 className="footer_info_title">Riyo Remit</h2>
                                    <p className="pe-lg-5">
                                    Riyo Remit is a UK-registered company with incorporation number 10816199. We are supervised in the UK by the FCA (FRN 838694) and are also regulated by HMRC (reference number XWML00000142251).
                                    </p>
                                    <ul className="social_links_block unordered_list">
                                        <li className="facebook"><NavLink to="#!" target='_blank'><FaFacebookSquare /></NavLink></li>
                                        <li className="twitter"><NavLink to="#!" target='_blank'><FaSquareXTwitter /></NavLink></li>
                                        <li className="linkdin"><NavLink to="#!" target='_blank'><FaLinkedin /></NavLink></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <h3 className="footer_info_title">Quick Links</h3>
                                <ul className="iconlist_block unordered_list_block mb-0">
                                    {/* <li>
                                        <NavLink to="#!" onClick={scrollToTop}>
                                            <span className="iconlist_text">About Us</span>
                                        </NavLink>
                                    </li> */}
                                    <li>
                                        <NavLink to="/general-faq" onClick={scrollToTop}>
                                            <span className="iconlist_text">FAQ's</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/contact" onClick={scrollToTop}>
                                            <span className="iconlist_text">Contact Us</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/help" onClick={scrollToTop}>
                                            <span className="iconlist_text">Help</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <h3 className="footer_info_title">Money Transfer</h3>
                                <ul className="iconlist_block unordered_list_block mb-0">
                                    <li>
                                        <NavLink to="/money-transfer-to-somalia" onClick={scrollToTop}>
                                            <span className="iconlist_text">Money Transfer to Somalia</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/money-transfer-to-ethiopia" onClick={scrollToTop}>
                                            <span className="iconlist_text">Money Transfer to Ethiopia</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/money-transfer-to-kenya" onClick={scrollToTop}>
                                            <span className="iconlist_text">Money Transfer to Kenya</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/money-transfer-to-uganda" onClick={scrollToTop}>
                                            <span className="iconlist_text">Money Transfer to Uganda</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <hr className="mb-0" />
                        <div className="footer_bottom">
                            <div className="row">
                                <div className="col-lg-6">
                                    <p className="copyright_text mb-0">
                                        Copyright © <script type="text/javascript">document.write(new Date().getFullYear());</script>
                                        <NavLink to="/" onClick={scrollToTop}> Riyo Remit </NavLink> All Rights Reserved.
                                    </p>
                                </div>
                                <div className="col-lg-6">
                                    <ul className="iconlist_block mb-0 unordered_list justify-content-lg-end">
                                        <li>
                                            <NavLink to="/terms-and-conditions" onClick={scrollToTop}>
                                                <span className="iconlist_text">Terms & Conditions</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/privacy-policy" onClick={scrollToTop}>
                                                <span className="iconlist_text">Privacy Policy</span>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/complaint-policy" onClick={scrollToTop}>
                                                <span className="iconlist_text">Complaint Policy</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="decoration_item shape_shadow_1">
                    <img src="assets/images/shapes/shape_circle_2.svg" alt="Shape Shadow 1" />
                </div>
                <div className="decoration_item shape_shadow_5">
                    <img src="assets/images/shapes/shape_circle_2.svg" alt="Shape Shadow 5" />
                </div>
            </footer>
            {/* Site Footer - End================================================== */}

            {showCookiesConsent && (
                <div className="wrapper-cookie">
                    <div className="d-flex gap-2" style={{ alignItems: 'flex-start' }}>
                        <img src="assets/images/cookies.png" alt="cookies consent" />
                        <h5 className='text-white'>Cookies Consent</h5>
                    </div>
                    <div className="content mt-3">
                        <p className='text-white'>
                            We use cookies to improve your browsing experience and for marketing purposes.
                        </p>
                        <div>
                            <button className="btn bg-primary text-dark" onClick={acceptCookies}>
                                <span className='txt'>I understand</span>
                            </button>
                            <NavLink to="/privacy-policy" className="item ms-3" style={{ textDecoration: 'underline' }} onClick={scrollToTop}>
                                Privacy Policy
                            </NavLink>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Footer;
